import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import type { SearchResults } from '@/composables/useOnboarding/types'
import SearchServiceAdapter from '@/composables/useListingsSearch/adapters/SearchServiceAdapter'
import { useLocationsStore } from '@/stores/locationsStore'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import { extractListingIdsFromProjectsSummaries } from '@/composables/useOnboarding/utils'
import { CURRENCY_BY_LOCALE } from '@/utils/number'

const opportunityTypeEventMap: Record<string, string> = {
  living: 'LIVING',
  investing: 'INVESTMENT'
}

export default function useListingsSearch (options: { screen: string, filtersOverride?: any, locationsOverride?: any }) {
  // @ts-ignore
  const { $sobreplanosDataService, $trackEvent } = useNuxtApp()
  // @ts-ignore
  const { locale } = useI18n()
  // @ts-ignore
  const { isMobileOrTablet } = useDevice()

  const locationsStore = useLocationsStore()
  const searchFiltersStore = useSearchFiltersStore()

  const { currentFilters, locationParams } = storeToRefs(searchFiltersStore)

  if (options.filtersOverride) currentFilters.value = { ...currentFilters.value, ...options.filtersOverride }

  const results = ref<SearchResults | null>(null)
  const error = ref(null)
  const isLoading = ref(false)

  const fetchListings = async () => {
    isLoading.value = true
    try {
      const searchServiceAdapter = new SearchServiceAdapter({ $sobreplanosDataService, locationsQueryUtils: locationsStore.locationsQueryUtils })
      const response = await searchServiceAdapter.search(currentFilters.value, { ...locationParams.value, ...options.locationsOverride })
      results.value = response

      return results.value
    } catch (err) {
      error.value = err
    } finally {
      isLoading.value = false
    }
  }

  watch(currentFilters, fetchListings)

  const emitListingsSearchedEvent = (payload: { userAction: string }) => {
    const listingsToEmit = [results.value.outstandingProject, ...results.value.exactMatchProjects].filter(Boolean)
    const listingsIds = extractListingIdsFromProjectsSummaries(listingsToEmit)

    const locations = [
      ...Object.values(locationParams.value),
      ...currentFilters.value.neighborhoods
    ].filter(Boolean)

    const data: any = {
      listings: listingsIds,
      locations,
      screen: options.screen,
      device_platform: isMobileOrTablet ? 'WEB_MOBILE' : 'DESKTOP',
      user_action: payload.userAction
    }

    if (currentFilters.value.opportunityType) {
      data.opportunity_type = opportunityTypeEventMap[currentFilters.value.opportunityType] ?? currentFilters.value.opportunityType.toUpperCase()
    }

    if (currentFilters.value.deliveryTerm) data.delivery_term = currentFilters.value.deliveryTerm

    if (currentFilters.value.bedroomsNumber) data.bedrooms_number = currentFilters.value.bedroomsNumber

    if (Object.values(currentFilters.value.price).some(Boolean)) data.price_range = { currency: CURRENCY_BY_LOCALE[locale.value], min: currentFilters.value.price.min, max: currentFilters.value.price.max }

    if (Object.values(currentFilters.value.area).some(Boolean)) data.area_range = { min: currentFilters.value.area.min, max: currentFilters.value.area.max }

    if (currentFilters.value.q) data.query_text = currentFilters.value.q

    $trackEvent({
      name: 'listings_searched',
      version: '3-0-0',
      data
    })
  }

  const hasError = computed(() => error.value !== null)
  const isFirstCall = ref(false)

  watch(isLoading, (newResults) => {
    if (newResults) {
      isFirstCall.value = results.value === null

      return
    }

    if (process.client && !isFirstCall.value) {
      emitListingsSearchedEvent({ userAction: 'USER_APPLIED_FILTERS' })
    }

    isFirstCall.value = false
  }, { deep: true, immediate: false, flush: 'post' })

  return {
    currentFilters,
    results,
    search: fetchListings,
    isLoading,
    hasError,
    error,
    emitListingsSearchedEvent
  }
}
