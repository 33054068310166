<script lang="ts" setup>
import SearchResultsContent from '@/components/SearchResultsContent/index.vue'

definePageMeta({
  layout: 'empty'
})
</script>

<script lang="ts">
export default {
  name: 'PageSearchPropertyCityZoneNeighborhood'
}
</script>

<template>
  <SearchResultsContent />
</template>
