import COUNTRIES_DICT from '@/constants/locations/countries.json'
import type { SeoMetadata } from '@/services/sobreplanosDataService/types/seo-metadata'

export const parseMetadata = (payload: {metadata: SeoMetadata, resultsCount: number}, config: { t: any, locale: string | 'co' | 'mx' }) => {
  const metadata: any = { ...payload.metadata }

  const totalResultsTitle = payload.resultsCount > 20 ? payload.resultsCount.toString() : ''

  if (payload.metadata.title_h1) {
    metadata.title_h1 = payload.metadata.title_h1.replace(/\[lh-total-properties\]/, totalResultsTitle)
  }

  if (payload.metadata.meta_title) {
    metadata.meta_title = payload.metadata.meta_title.replace(/\[lh-total-properties\]/, totalResultsTitle)
  }

  if (payload.metadata.og_title) {
    metadata.og_title = payload.metadata.og_title.replace(/\[lh-total-properties\]/, totalResultsTitle)
  }

  return {
    robots: metadata.robots || 'index, follow',
    title: metadata.meta_title || config.t('investment_simulator.seo_metadata.title', { location: COUNTRIES_DICT[config.locale] }),
    titleH1: metadata.title_h1,
    ogTitle: metadata.og_title || config.t('investment_simulator.seo_metadata.title'),
    description: metadata.meta_description || config.t('investment_simulator.seo_metadata.description'),
    ogImage: metadata.og_image || 'https://lahaus.imgix.net/static/default/default-share-social-media-v2.jpg',
    ogLocale: config.t('investment_simulator.seo_metadata.locale'),
    ogSiteName: 'La Haus',
    twitterCard: 'summary',
    twitterSite: `@${config.t('investment_simulator.seo_metadata.twitterSite')}`,
    twitterCreator: `@${config.t('investment_simulator.seo_metadata.twitterSite')}`,
    canonicalUrl: metadata.canonical_url || ''
  }
}
