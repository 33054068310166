import { useLocationsStore } from '@/stores/locationsStore'
import { parseSearchFilters } from '@/components/SearchFilters/utils'

export default function useSearchResultsMetadata () {
  // @ts-ignore
  const route = useRoute()
  // @ts-ignore
  const { locale } = useI18n()
  // @ts-ignore
  const { $sobreplanosDataService } = useNuxtApp()

  const locationsStore = useLocationsStore()

  const getMetadata = async () => {
    // @ts-ignore
    const baseMetadata = await $sobreplanosDataService.getSeoMetadata(route.path)

    const { query_url: customSrpUrl } = baseMetadata?.additional_info || {}
    let customSrpOptions = {}

    if (customSrpUrl) {
      // @ts-ignore
      const requestUrl = useRequestURL()
      const query = new URL(customSrpUrl, requestUrl.origin)
      const queryParams = Object.fromEntries(query.searchParams)

      customSrpOptions = {
        filtersOverride: parseSearchFilters(queryParams, locale.value, locationsStore.locationsQueryUtils),
        locationsOverride: {
          citySlug: query.pathname.split('/')[3],
          zoneSlug: query.pathname.split('/')[4],
          neighborhoodSlug: query.pathname.split('/')[5]
        }
      }
    }

    return { baseMetadata, customSrpOptions }
  }

  return {
    getMetadata
  }
}
